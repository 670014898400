@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --header-height: 3.5rem;
  --first-color: hsl(0, 88%, 62%);
  --title-color: hsl(0, 0%, 0%);
  --text-color: hsl(0, 0%, 0%);
  --body-font: "Montserrat", sans-serif;
  --second-font: "Dancing Script", cursive;
  --h2-font-size: 1.2rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 550;
  --z-tooltip: 10;
  --z-fixed: 100;
}

html {
  height: 100dvh
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: var(--body-font);
  -webkit-overflow-scrolling: touch;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.main-content {
  padding-top: 5rem;
}

#root,
.main-content,
.footer {
  width: 100%;
}

.title__img-visible {
  opacity: 0.5;
  max-height: 500px;
  width: 100%;
  z-index: 100;
  object-fit: cover;
  transition: opacity 0.8s ease; 
}

.title__img-hidden {
  opacity: 0;
  max-height: 500px;
  width: 100%;
  z-index: 100;
  object-fit: cover;
}

.carousel {
  position: relative;
  overflow: hidden;
}

.slide {
  display: none;
}

.slide.active {
  display: block;
}

.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
