.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(51, 51, 51);
  z-index: var(--z-fixed);
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  position: relative;
  height: var(--header-height);
  margin: 1rem;
  white-space: inherit;
  font-size: 14.5px;
}

.nav__logo {
  font-family: "Dancing Script", cursive;;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  vertical-align: middle;
  font-size: 20px;
}
.nav__logo img{
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid rgb(0, 0, 0);
  justify-content: center;
  align-self: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  float: left;
}

.nav__logo__title{
  color: rgb(238, 238, 238);
  padding-top: 15px;
  display: flex;
  font-style: none;
}

.nav__toggle,
.nav__close {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.nav__cta {
  background-color: #79ff72;
  color: var(--title-color);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
  padding: 0.75rem 1.5rem;
}

.nav__cta:hover {
  color: black !important;
  background-color: #459941;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  font-size: 15px !important;
}

.nav__list li:last-child{
  margin-top: 50px;
}

.nav__link {
  color: rgb(238, 238, 238);
  font-weight: var(--font-semi-bold);
  position: relative;
  transition: color 0.4s;
}

.nav__link:not(.dropdown)::before {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #ffffff;
  transition: width 0.4s;
}

.nav__link:not(.dropdown):hover::before {
  width: 100%;
}

.dropdown .nav__link::before {
  display: none; /* Hide the underline for dropdown items */
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.show-menu {
  right: 0;
}

.nav__list__web {
  display: flex;
  flex-direction: row;
  column-gap: 1.6rem;
  list-style-type: none;
  box-sizing: border-box;
  margin-top: 12px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  background: rgb(51, 51, 51);
  min-width: 160px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-content a {
  display: block;
  text-decoration: none;
  padding: 15px 15px 5px 15px;
  margin-bottom: 5px;
  font-size: 15px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.icon {
  position: relative;
  top: 2px;
  font-size: 12px;
}

@media screen and (max-width: 1400px) {
  .nav__list__web{
    column-gap: 0.9rem;
    font-size: 11px !important;
  }
  .dropdown-content a {
    font-size: 12px;
  }
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
